const rok = {
    alert: {
        reLogin: "주소가 변경되었습니다. 다시 로그인하세요",
        copied: "복사 성공",
        connectWallet: "지갑을 연결하세요",
        notBound: "추천인 주소가 연결되지 않았습니다. 연결하세요",
        inputParent: "추천인 주소를 입력하세요",
        addressError: "올바른 지갑 주소를 입력하세요",
        parentError: "추천인 주소가 잘못되었습니다. 다시 입력하세요",
        boundSuccess: "연결 성공",
        boundFailed: "연결 실패. 다시 시도하세요",
        success: "성공",
        failed: "실패",
        soon: "곧 오픈...",
        insufficient: "잔액 부족",
        quotaInsufficient: "할당량 부족",
        amountError: "금액 오류",
        minAmountError: "최소",
        redeemed: "주문이 없거나 이미 상환되었습니다",
        enter: "확인",
        congratulation: "축하합니다",
        walletInsufficient: "지갑 잔액 부족",
        copySuccess: "복사 성공"
    },
    header: {
        link: "지갑 연결",
        home: "홈",
        lp: "스테이킹",
        award: "보상",
        solfi: "SOLFI",
        user: "내 정보",
        ido: "IDO",
        inc: "© 2024 SolFi Inc. 모든 권리 보유.",
        bind: "연결",
        bindPla: "초대자 주소를 입력하세요",
        bindTitle: "초대자 주소를 연결하세요"
    },
    home: {
        launchTitle: "Sol.fi 는",
        launchTitle1: "자동화 DeFi 전략 라이브러리",
        launchTitle2: "Solana 블록체인 사용자에게 간단하고 효율적인 탈중앙화 금융 서비스를 제공하는 것을 목표로 합니다.",
        launchDesc: "우리는 사용자가 다양한 스테이킹 및 유동성 채굴 활동에 쉽게 참여할 수 있는 개방적이고 다양한 생태계를 구축하여 안정적인 수익 성장을 달성할 수 있도록 합니다.",
        but1: "스테이킹",
        but2: "백서",
        diversifiedTitle0: "간단함",
        diversifiedTitle10: "다양성",
        diversifiedDesc0: "Sol.fi 는 SOL 스테이킹 및 탈중앙화 거래를 위한 일체형 솔루션을 제공하여 사용자가 복잡한 작업 없이 다양한 DeFi 활동에 참여할 수 있도록 합니다.",
        diversifiedTitle1: "탈중앙화",
        diversifiedTitle11: "금융 프로토콜",
        diversifiedDesc1: "Solana 블록체인의 일부로서, Sol.fi 는 사용자 자산의 안전성을 보장하고 저비용 거래 및 스테이킹 경험을 제공하는 완전 탈중앙화 금융 프로토콜을 구축합니다.",
        diversifiedTitle2: "다중 프로토콜",
        diversifiedTitle12: "지원",
        diversifiedDesc2: "Sol.fi 의 유동성 풀은 현재 Jupiter, Raydium, Orca 등의 유명 프로토콜에 스테이킹되어 있으며, 향후 더 많은 고품질 DeFi 프로토콜이 추가되어 사용자가 더 넓은 투자 선택과 수익 기회를 누릴 수 있도록 할 것입니다.",
        benefit: "주요 이점",
        benefitTitle0: "높은 수익",
        benefitText0: "사용자는 SOL 자산을 스테이킹하여 높은 수익을 얻고 자산 가치를 증가시킬 수 있습니다.",
        benefitTitle1: "다양한 투자",
        benefitText1: "Sol.fi 를 통해 사용자는 원스톱으로 스테이킹 및 유동성 채굴에 참여하여 자금을 다양하게 투자하고 위험을 줄일 수 있습니다.",
        benefitTitle2: "개방형 생태계",
        benefitText2: "개방형 생태계의 일환으로서, Sol.fi 는 지속적으로 확장되어 더 많은 우수한 DeFi 프로토콜을 추가하여 사용자에게 더 넓은 금융 서비스와 수익 기회를 제공할 것입니다.",
        friend: "파트너",
        friendDesc: "Sol.fi 의 스테이킹 풀 전략은 DeFi 분야의 최고 스테이킹 풀 구축자 중 하나인 Seven Seas 가 제공합니다. Seven Seas 와의 협력을 통해 Sol.fi 는 사용자가 안정적이고 신뢰할 수 있는 스테이킹 서비스를 제공받고, 스테이킹 전략을 지속적으로 최적화하여 더 높은 수익을 실현할 수 있도록 합니다. Sol.fi 를 통해 Solana 생태계의 발전에 기여하고, 사용자에게 효율적이고 안전한 탈중앙화 금융 서비스를 제공하며, 블록체인 기술의 편리함과 혁신을 더 많은 사람들이 누릴 수 있도록 하는 것을 목표로 합니다."
    },
    pay: {
        desc: 'Solana에서 당신의 미래를 보장합니다 - 신뢰할 수 있는 지갑으로 초고속 거래를 경험하세요!',
        days: '일',
        hours: '시간',
        minutes: '분',
        seconds: '초',
        android: '안드로이드 다운로드',
        ios: '앱 스토어 다운로드'
    },
    lp: {
        title: "SOL을 더 빨리 스택하기",
        title1: "SolFi 는 SOL 스테이킹을 더 간단하게 만들어 투자자와 커뮤니티 구성원에게 지속적인 가치와 상당한 수익을 제공합니다.",
        stackDesc: "SolFi 는 SOL 스테이킹을 더 간단하게 만들어 투자자와 커뮤니티 구성원에게 지속적인 가치와 상당한 수익을 제공합니다.",
        stackList1: "내 스테이킹",
        stackList2: "획득한 수익",
        stackList3: "복리 수익 획득",
        lp: "스테이킹",
        underway: "스테이킹 중",
        earn: "획득한 수익",
        yet: "출금 가능한 수익",
        redeem: "상환",
        yetBut: "출금",
        accredit: "승인",
        record: "스테이킹 기록",
        tokenTitle: "복리 수익",
        tokenDesc: "스테이킹으로 발생한 수익을 출금하지 않으면 복리 수익이 발생하며, 1SOLFI = 1SOL 비율로 SOLFI 토큰이 발행됩니다. SolFi Token(SOLFI) 는 전체 플랫폼의 기능 토큰으로 AI 서비스 통합과 커뮤니티 거버넌스에 참여할 수 있으며, 미래에 높은 가치를 가질 것입니다.",
        tokenList1: "복리 수익을 받을 수 있는지 여부",
        tokenList2: "획득한 복리 수익",
        tokenList3: "출금 가능한 복리 수익",
        yes: "예",
        no: "아니오",
        back: "스테이킹으로 돌아가기",
        money: "금액",
        timeStart: "스테이킹 시간",
        timeEnd: "만료 시간",
        cancel: "자동 갱신 취소",
        more: "더보기...",
        max: "최대",
        quotaRemain: "잔액",
        usable: "스테이킹 가능",
        redeemed: "상환 완료",
        redeeming: "상환 중...",
        staking: "스테이킹 중..."
    },
    award: {
        communityDesc: "커뮤니티 발전에 기여한 커뮤니티 구성원은 커뮤니티 특별 보상을 받게 됩니다.",
        communityList1: "받은 보상",
        communityList2: "출금 완료",
        communityList3: "출금 가능",
        communityBut: "출금",
        record: "보상 기록",
        listType1: "직접 추천 보상",
        listType2: "팀 보상",
        listType3: "동급 보상",
        listType4: "배당 보상"
    },
    top: {
        tokenDesc: "SolFi Token(SOLFI)는 플랫폼 전체의 기능 토큰으로 AI 서비스 통합 및 커뮤니티 거버넌스에 참여할 수 있으며, 미래에 높은 가치를 지닐 것입니다.",
        price: "가격",
        allAmount: "총 공급량",
        market: "시가총액",
        my: "내 SOLFI",
        balance: "지갑 잔액",
        buy: "스왑",
        approve: "SOL 승인",
        approveTop: "SOLFI 승인",
        earn: "획득한 수익",
        getYet: "출금 가능",
        get: "출금"
    },
    ido: {
        turn: "현재 라운드",
        all: "IDO 총량",
        price: "가격",
        limit: "구독 한도",
        ps: "잠금 해제 방식: []개월 후 12개월에 걸쳐 점진적으로 해제",
        my: "내 한도",
        nobuy: "구독하지 않음",
        balance: "잔액",
        custom: "예상 수익",
        buy: "지금 구독",
        buyYet: "구독 완료",
        record: "구독 기록",
        buyTrun: "구독 라운드",
        buyUsdt: "SOL 구독",
        getTop: "SOLFI 획득",
        unlock: "다음 잠금 해제 시간",
        unlockYet: "잠금 해제된 SOLFI",
        claimable: "출금 가능한 SOLFI",
        get: "출금",
        nodataText: "구독 기록 없음"
    },
    user: {
        desc: "SolFi를 공유하여 더 많은 사람들이 도움을 받고 블록체인 분야에서 상당한 수익을 얻을 수 있도록 도와주세요.",
        joinTime: "가입 시간",
        memberTeam: "팀 구성원",
        memberDirect: "직접 추천 구성원",
        teamLp: "팀 스테이킹",
        myLink: "내 초대 링크",
        myTeam: "내 팀",
        personage: "개인 스테이킹",
        cur: "현재",
        lookDown: "하위 레벨 보기",
        copy: "복사",
        info: "개인 정보"
    }
}

export default rok;


