<template>
    <div id="app">
        <router-view />
<!--        <div id="nav" v-if="show">-->
<!--            <router-link to="/">-->
<!--                <img src="./static/images/home.png" v-if="link" />-->
<!--                <img src="./static/images/home_s.png" v-else />-->
<!--                <span :style="!link?'color: #FFFFFF':''">{{$t('tabbar.home')}}</span>-->
<!--            </router-link>-->
<!--            <router-link class="subscription" to="/subscription">-->
<!--                <img src="./static/images/subscription.png" v-if="link!=='subscription'" />-->
<!--                <img src="./static/images/subscription_s.png" v-else />-->
<!--                <span :style="link==='subscription'?'color: #FFFFFF':''">{{$t('tabbar.subscription')}}</span>-->
<!--            </router-link>-->
<!--            &lt;!&ndash; <router-link to="/exchange">-->
<!--                <img src="./static/images/exchange.png" v-if="link!=='exchange'" />-->
<!--                <img src="./static/images/exchange_s.png" v-else />-->
<!--                <span :style="link==='exchange'?'color: #FFFFFF':''">{{$t('tabbar.exchange')}}</span>-->
<!--            </router-link> &ndash;&gt;-->
<!--            <router-link to="/user">-->
<!--                <img src="./static/images/user.png" v-if="link!=='user'" />-->
<!--                <img src="./static/images/user_s.png" v-else/>-->
<!--                <span :style="link==='user'?'color: #FFFFFF':''">{{$t('tabbar.user')}}</span>-->
<!--            </router-link>-->
<!--        </div>-->
    </div>
</template>
<script>
export default ({
    data() {
        return{
            link: '',
            show: true
        }
    },
    watch:{
        $route(newval,oldval){
            this.link = newval.path.slice(1,newval.path.length);
        }
    },
    methods:{
        showTabBar(bool){
            this.show = bool;
        },
        handleNewEvent(event) {
        },
    }
})
</script>

<style>
    body {
        margin: 0;
        font-size: 14px;
        color: white;
    }
    #app {
        width: 100%;
        /* max-width: 768px; */
        min-height: 100vh;
        overflow: hidden;
        margin: 0 auto;
        background: #F5F4F6;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        background-color: #2D1562;
    }
    #nav {
        position: fixed;
        bottom: 0;
        z-index: 1001;
        width: 100%;
        /* max-width: 768px; */
        height: 70px;
        display: flex;
        justify-content: space-around;
        background-color: rgb(30,30,30);
        /* border-top: 1px solid #e0e0e0; */
    }
    #nav a{
        color: #999;
        text-decoration: none;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        font-weight: 500;
        align-items: center;
    }
    #nav a img{
        width: 24px;
        margin: 10px 0 8px;
    }
    button:focus{
        outline: none;
    }
    .module-wid{
        width: calc(100% - 52px);
        margin: 0 auto;
        background-color: white;
        padding: 16px;
        border-radius: 4px;
    }
    button{
        border: none;
        cursor: pointer;
    }
    .more{
        text-align: center;
        margin: 20px auto;
        font-size: 14px;
        color:#777777
    }
    .subscription{
        margin-top: -24px;
    }
    .subscription img{
        width: 47px !important;
        height: 47px !important;
    }
    .no-data{
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
    .no-data img{
        width: 100px;
        height: 100px;
    }
    .top-gap{
        height: 80px;
    }
    @media screen and (max-width:768px) {
        .top-gap{
            height: 70px;
        }
    }
</style>
