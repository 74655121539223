const jp = {
    alert:{
        reLogin: 'アドレスが変更されました。再度ログインしてください。',
        copied: 'コピー成功',
        connectWallet: 'ウォレットを接続してください',
        notBound: '紹介者アドレスが未設定です。設定してください',
        inputParent: '紹介者アドレスを入力してください',
        addressError: '正しいウォレットアドレスを入力してください',
        parentError: '紹介者アドレスが間違っています。再度入力してください',
        boundSuccess: '設定成功',
        boundFailed: '設定失敗。再試行してください',
        success: '成功',
        failed: '失敗',
        soon: 'まもなく開始...',
        insufficient: '残高不足',
        quotaInsufficient: '枠が不足しています',
        amountError: '金額エラー',
        minAmountError: '最低金額',
        redeemed: '注文が存在しないか、すでに償還されています',
        enter: '確定',
        congratulation: '合成おめでとうございます',
        walletInsufficient: 'ウォレット残高不足',
        copySuccess: 'コピー成功'
    },
    header: {
        link: 'ウォレットを接続',
        home: 'ホーム',
        lp: 'ステーキング',
        award: '報酬',
        solfi: 'SOLFI',
        user: 'マイページ',
        ido: 'IDO',
        inc: '© 2024 SolFi Inc. 全著作権所有。',
        bind: 'バインド',
        bindPla: '紹介者のアドレスを入力してください',
        bindTitle: '紹介者のアドレスを設定してください'
    },
    home:{
        launchTitle: 'Sol.fiは',
        launchTitle1: '自動化されたDeFi戦略ライブラリです',
        launchTitle2: 'Solanaブロックチェーン上のユーザーにシンプルかつ効率的な分散型金融サービスを提供することを目指しています。',
        launchDesc: '私たちは、ユーザーがSOL資産を活用してさまざまなステーキングおよび流動性マイニング活動に簡単に参加し、安定した収益成長を実現できるオープンで多様なエコシステムを構築することに尽力しています。',
        but1: 'ステーキング',
        but2: 'ホワイトペーパー',
        diversifiedTitle0: 'シンプル',
        diversifiedTitle10: '多様性',
        diversifiedDesc0: 'Sol.fiは、SOLステーキングと分散型取引の統合ソリューションを提供し、ユーザーが複雑な操作なしに多様なDeFi活動に参加できるようにします。',
        diversifiedTitle1: '分散型',
        diversifiedTitle11: '金融プロトコル',
        diversifiedDesc1: 'Solanaブロックチェーンの一部として、Sol.fiは完全に分散型の金融プロトコルを構築し、ユーザーの資産を安全に保ち、低コストの取引およびステーキング体験を提供します。',
        diversifiedTitle2: '複数プロトコル',
        diversifiedTitle12: 'サポート',
        diversifiedDesc2: 'Sol.fiの流動性プールは現在、Jupiter、Raydium、およびOrcaなどの有名なプロトコルでステーキングされています。将来的には、さらに多くの優れたDeFiプロトコルが追加され、ユーザーに広範な投資オプションと収益機会を提供します。',
        benefit: '主な利益',
        benefitTitle0: '高利益',
        benefitText0: 'ユーザーはSOL資産をステーキングすることで高い利益を得て、資産価値を増やすことができます。',
        benefitTitle1: '多様な投資',
        benefitText1: 'Sol.fiは、ユーザーがステーキングおよび流動性マイニングに一元的に参加し、資金の多様な投資を実現し、リスクを低減することを可能にします。',
        benefitTitle2: 'オープンエコシステム',
        benefitText2: 'オープンエコシステムの一部として、Sol.fiは常に拡大し、より多くの優れたDeFiプロトコルに参加して、ユーザーにより広範な金融サービスと収益機会を提供します。',
        friend: 'パートナー',
        friendDesc: 'Sol.fiのステーキングプール戦略は、DeFi分野のトップステーキングプールビルダーの1つであるSeven Seasによって提供されています。Seven Seasとの協力により、Sol.fiはユーザーに安全で信頼性の高いステーキングサービスを提供し、より高い収益を実現するためにステーキング戦略を最適化し続けます。Sol.fiを通じて、私たちはSolanaエコシステムの発展に貢献し、ユーザーに効率的で安全な分散型金融サービスを提供し、ブロックチェーン技術がもたらす便利さと革新をより多くの人々に享受していただきたいと考えています。'
    },
    pay: {
        desc: 'Solanaであなたの未来を保障 - 信頼できるウォレットで、超高速トランザクションを実現！',
        days: '日',
        hours: '時',
        minutes: '分',
        seconds: '秒',
        android: 'Androidダウンロード',
        ios: 'App Storeダウンロード'
    },
    lp:{
        title: 'SOLをより早くスタック',
        title1: 'SolFiはSOLのステーキングをよりシンプルにし、投資家とコミュニティメンバーに継続的な価値と顕著なリターンを提供します。',
        stackDesc: 'SolFiはSOLのステーキングをよりシンプルにし、投資家とコミュニティメンバーに継続的な価値と顕著なリターンを提供します。',
        stackList1: '私のステーキング',
        stackList2: '獲得済み',
        stackList3: '複利収益',
        lp: 'ステーキング',
        underway: 'ステーキング中',
        earn: '獲得した収益',
        yet: '引き出し可能な収益',
        redeem: '償還',
        yetBut: '引き出す',
        accredit: '認可',
        record: 'ステーキング履歴',
        tokenTitle: '複利収益',
        tokenDesc: 'ステーキングから得られる収益を引き出さない場合、複利収益が発生し、1SOLFI = 1SOLでSOLFIトークンが発行されます。SolFi Token(SOLFI)はプラットフォーム全体の機能トークンとして、AIサービスの体験やコミュニティガバナンスに参加でき、将来的には非常に価値があるものとなるでしょう。',
        tokenList1: '複利を享受できるか',
        tokenList2: '獲得した複利収益',
        tokenList3: '引き出し可能な複利収益',
        yes: 'はい',
        no: 'いいえ',
        back: 'ステーキングに戻る',
        money: '金額',
        timeStart: 'ステーキング開始時間',
        timeEnd: '終了時間',
        cancel: '自動更新をキャンセル',
        more: 'もっと見る...',
        max: '最大',
        quotaRemain: '残高',
        usable: 'ステーキング可能',
        redeemed: '償還済み',
        redeeming: '償還中...',
        staking: 'ステーキング中...'
    },
    award:{
        communityDesc: 'コミュニティの発展に貢献したメンバーは、コミュニティ特別報酬を受け取ることができます。',
        communityList1: '獲得した報酬',
        communityList2: '引き出し済み',
        communityList3: '引き出し可能',
        communityBut: '引き出す',
        record: '報酬履歴',
        listType1: '直接紹介報酬',
        listType2: 'チーム報酬',
        listType3: '同ランク報酬',
        listType4: '配当報酬'
    },
    top:{
        tokenDesc: 'SolFi Token(SOLFI)はプラットフォーム全体の機能トークンとして、AIサービスの体験やコミュニティガバナンスに参加でき、将来的には非常に価値があるものとなるでしょう。',
        price: '価格',
        allAmount: '総供給量',
        market: '時価総額',
        my: '私のSOLFI',
        balance: 'ウォレット残高',
        buy: 'スワップ',
        approve: 'SOLの承認',
        approveTop: 'SOLFIの承認',
        earn: '獲得済み',
        getYet: '引き出し可能',
        get: '引き出す'
    },
    ido:{
        turn: '現在のラウンド',
        all: 'IDO総量',
        price: '価格',
        limit: '購入限度',
        ps: 'ロック解除方式：[]か月後にロック解除され、12か月間にわたり線形にロック解除されます',
        my: '私の限度',
        nobuy: '未購入',
        balance: '残高',
        custom: '予想獲得',
        buy: '今すぐ購入',
        buyYet: '購入済み',
        record: '購入履歴',
        buyTrun: '購入ラウンド',
        buyUsdt: 'SOLで購入',
        getTop: 'SOLFIを獲得',
        unlock: '次のロック解除時間',
        unlockYet: 'ロック解除されたSOLFI',
        claimable: '引き出し可能なSOLFI',
        get: '引き出す',
        nodataText: '購入履歴がありません'
    },
    user:{
        desc: 'SolFiをシェアし、ブロックチェーン分野で顕著な収益を得たい人々を支援します。',
        joinTime: '登録時間',
        memberTeam: 'チームメンバー',
        memberDirect: '直接紹介メンバー',
        teamLp: 'チームステーキング',
        myLink: '私の紹介リンク',
        myTeam: '私のチーム',
        personage: '個人ステーキング',
        cur: '現在',
        lookDown: '下位を見る',
        copy: 'コピー',
        info: '個人情報'
    }
}

export default jp;
