const zh = {
    alert:{
        reLogin: '地址發生改變，請重新登錄',
        copied: '復制成功',
        connectWallet: '請連接錢包',
        notBound: '未綁定推薦人地址，請綁定',
        inputParent: '請輸入推薦人地址',
        addressError: '請輸入正確的錢包地址',
        parentError: '推薦人地址錯誤，請重新輸入',
        boundSuccess: '綁定成功',
        boundFailed: '綁定失敗，請重試',
        success: '成功',
        failed: '失敗',
        soon: '即將開放...',
        insufficient: '余額不足',
        quotaInsufficient: '額度不足',
        amountError: '金額錯誤',
        minAmountError: '最低',
        redeemed: '訂單不存在或已經贖回',
        enter: '確定',
        congratulation: '恭喜您合成',
        walletInsufficient: '錢包余額不足',
        copySuccess: '復制成功'
    },
    header: {
        link: '連接錢包',
        home: '首頁',
        lp: '質押',
        award: '獎勵',
        solfi: 'SOLFI',
        user: '我的',
        ido: 'IDO',
        inc: '© 2024 SolFi Inc. 保留所有權利。',
        bind: '綁定',
        bindPla: '請輸入邀請人地址',
        bindTitle: '請綁定邀請人地址'
    },
    home:{
        launchTitle: 'Sol.fi 是壹個',
        launchTitle1: '自動化 DeFi 策略庫',
        launchTitle2: '旨在為 Solana 區塊鏈上的用戶提供簡單而高效的去中心化金融服務。',
        launchDesc: '我們致力於打造壹個開放、多樣化的生態系統，讓用戶可以輕松地利用他們的 SOL 資產參與各種質押和流動性挖礦活動，從而實現穩健的收益增長。',
        but1: '質押',
        but2: '白皮書',
        diversifiedTitle0: '簡單',
        diversifiedTitle10: '多樣化',
        diversifiedDesc0: 'Sol.fi 提供了 SOL 質押和去中心化交易的壹體化解決方案，使用戶無需繁瑣的操作即可參與多樣化的 DeFi 活動。',
        diversifiedTitle1: '去中心化',
        diversifiedTitle11: '金融協議',
        diversifiedDesc1: '作為 Solana 區塊鏈上的壹部分，Sol.fi 打造了壹個完全去中心化的金融協議，確保用戶的資產安全並享受低成本的交易和質押體驗。',
        diversifiedTitle2: '多協議',
        diversifiedTitle12: '支持',
        diversifiedDesc2: 'Sol.fi 的流動性礦池目前在 Jupiter、Raydium 和 Orca 等知名協議上進行質押，未來還將陸續加入更多優質的 DeFi 協議，為用戶提供更廣泛的投資選擇和收益機會。',
        benefit: '主要收益',
        benefitTitle0: '高額利潤',
        benefitText0: '用戶可以通過質押 SOL 資產來賺取高額利潤，實現資產增值。',
        benefitTitle1: '多樣化投資',
        benefitText1: 'Sol.fi 讓用戶可以壹站式參與質押和流動性挖礦，實現資金的多樣化投資，降低風險。',
        benefitTitle2: '開放式生態系統',
        benefitText2: '作為開放式生態系統的壹部分，Sol.fi 將不斷擴展並加入更多優秀的 DeFi 協議，為用戶提供更廣泛的金融服務和收益機會。',
        friend: '合作夥伴',
        friendDesc: 'Sol.fi 的質押礦池策略由 Seven Seas 提供，他們是 DeFi 領域頂級的質押礦池建設者之壹。通過與 Seven Seas 的合作，Sol.fi 能夠為用戶提供安全可靠的質押服務，並持續優化質押策略以實現更高的收益。通過 Sol.fi，'
            + '我們希望為 Solana 生態系統的發展做出貢獻，為用戶提供高效、安全的去中心化金融服務，讓更多人能夠享受到區塊鏈技術所帶來的便利和創新。'
    },
    pay: {
        desc: '在 Solana 上保障您的未來 – 您可信賴的錢包，可實現閃電般的快速交易！',
        days: '天',
        hours: '時',
        minutes: '分',
        seconds: '秒',
        android: 'Android下載',
        ios: 'App Store下載'
    },
    lp:{
        title: 'Stack SOL faster',
        title1: 'SolFi將SOL質押變得更簡單，為投資者和社區成員提供持續的價值和可觀的回報。',
        stackDesc: 'SolFi將SOL質押變得更簡單，為投資者和社區成員提供持續的價值和可觀的回報。',
        stackList1: '我的質押',
        stackList2: '已賺取',
        stackList3: '已復利獲得',
        lp: '質押',
        underway: '質押中',
        earn: '已賺取收益',
        yet: '可提取收益',
        redeem: '贖回',
        yetBut: '提取',
        accredit: '授權',
        record: '質押記錄',
        tokenTitle: '復利收益',
        tokenDesc: '不提取質押產生的收益將產生復利收益，按1SOLFI = 1SOL配發SOLFI代幣。SolFi Token(SOLFI)將作為整個平臺的功能代幣，可參與體驗集成AI服務和社區治理，在未來將極具價值。',
        tokenList1: '是否可享受復利',
        tokenList2: '已獲得復利收益',
        tokenList3: '可提取復利收益',
        yes: '是',
        no: '否',
        back: '返回質押',
        money: '金額',
        timeStart: '質押時間',
        timeEnd: '到期時間',
        cancel: '取消自動續期',
        more: '更多...',
        max: '最大',
        quotaRemain: '余額',
        usable: '可質押',
        redeemed: '已贖回',
        redeeming: '贖回中...',
        staking: '質押中...'
    },
    award:{
        communityDesc: '為社區發展做出貢獻的社區成員，將獲得社區專項獎勵。',
        communityList1: '已獲取獎勵',
        communityList2: '已提取',
        communityList3: '可提取',
        communityBut: '提取',
        record: '獎勵記錄',
        listType1: '直推獎勵',
        listType2: '團隊獎勵',
        listType3: '平級獎勵',
        listType4: '分紅獎勵'
    },
    top:{
        tokenDesc: 'SolFi Token(SOLFI)將作為整個平臺的功能代幣，可參與體驗集成AI服務和社區治理，在未來將極具價值。',
        price: '價格',
        allAmount: '總供應量',
        market: '市值',
        my: '我的SOLFI',
        balance: '錢包余額',
        buy: 'Swap',
        approve: '批準SOL',
        approveTop: '批準SOLFI',
        earn: '已賺取',
        getYet: '可領取',
        get: '領取'
    },
    ido:{
        turn: '當前輪次',
        all: 'IDO總量',
        price: '價格',
        limit: '認購額度',
        ps: '解鎖方式：鎖定[]個月後，分12個月線性解鎖',
        my: '我的額度',
        nobuy: '未認購',
        balance: '余額',
        custom: '預計獲得',
        buy: '立即認購',
        buyYet: '已認購',
        record: '認購記錄',
        buyTrun: '認購輪次',
        buyUsdt: '認購SOL',
        getTop: '獲得SOLFI',
        unlock: '下壹次解鎖時間',
        unlockYet: '已解鎖SOLFI',
        claimable: '可領取SOLFI',
        get: '領取',
        nodataText: '暫無認購記錄'
    },
    user:{
        desc: '分享SolFi，幫助更多需要幫助的人們，在區塊鏈領域賺取可觀收益。',
        joinTime: '註冊時間',
        memberTeam: '團隊成員',
        memberDirect: '直推成員',
        teamLp: '團隊質押',
        myLink: '我的邀請鏈接',
        myTeam: '我的團隊',
        personage: '個人質押',
        cur: '當前',
        lookDown: '查看下級',
        copy: '復制',
        info: '個人信息'
    }
}

export default zh;
