const ru = {
    alert:{
        reLogin: 'Адрес изменен, пожалуйста, войдите снова',
        copied: 'Копирование успешно',
        connectWallet: 'Пожалуйста, подключите кошелек',
        notBound: 'Адрес рефери не привязан, пожалуйста, привяжите',
        inputParent: 'Введите адрес рефери',
        addressError: 'Введите правильный адрес кошелька',
        parentError: 'Неправильный адрес рефери, пожалуйста, введите заново',
        boundSuccess: 'Привязка успешна',
        boundFailed: 'Привязка не удалась, попробуйте снова',
        success: 'Успешно',
        failed: 'Не удалось',
        soon: 'Скоро откроется...',
        insufficient: 'Недостаточно средств',
        quotaInsufficient: 'Недостаточно квоты',
        amountError: 'Ошибка суммы',
        minAmountError: 'Минимальная сумма',
        redeemed: 'Заказ не существует или уже выкуплен',
        enter: 'Подтвердить',
        congratulation: 'Поздравляем с успешным слиянием',
        walletInsufficient: 'Недостаточно средств в кошельке',
        copySuccess: 'Копирование успешно'
    },
    header: {
        link: 'Подключить кошелек',
        home: 'Главная',
        lp: 'Стекинг',
        award: 'Награды',
        solfi: 'SOLFI',
        user: 'Мой аккаунт',
        ido: 'IDO',
        inc: '© 2024 SolFi Inc. Все права защищены.',
        bind: 'Привязать',
        bindPla: 'Введите адрес пригласителя',
        bindTitle: 'Привяжите адрес пригласителя'
    },
    home:{
        launchTitle: 'Sol.fi — это',
        launchTitle1: 'библиотека автоматических DeFi стратегий',
        launchTitle2: 'направленная на предоставление простых и эффективных децентрализованных финансовых услуг пользователям Solana блокчейна.',
        launchDesc: 'Мы стремимся создать открытую и разнообразную экосистему, которая позволяет пользователям легко использовать свои SOL активы для участия в различных стекинг и ликвидностных майнинг активностях, обеспечивая стабильный рост доходов.',
        but1: 'Стекинг',
        but2: 'Белая книга',
        diversifiedTitle0: 'Простой',
        diversifiedTitle10: 'Разнообразный',
        diversifiedDesc0: 'Sol.fi предоставляет интегрированное решение для стекинга SOL и децентрализованных торгов, позволяя пользователям участвовать в различных DeFi активностях без сложных операций.',
        diversifiedTitle1: 'Децентрализованный',
        diversifiedTitle11: 'Финансовый протокол',
        diversifiedDesc1: 'Как часть Solana блокчейна, Sol.fi создает полностью децентрализованный финансовый протокол, обеспечивая безопасность активов пользователей и низкие издержки на транзакции и стекинг.',
        diversifiedTitle2: 'Многопротокольная',
        diversifiedTitle12: 'Поддержка',
        diversifiedDesc2: 'Ликвидностный пул Sol.fi в настоящее время стейкается на известных протоколах, таких как Jupiter, Raydium и Orca. В будущем будут добавлены другие высококачественные DeFi протоколы, предоставляя пользователям более широкий выбор инвестиций и возможности для получения дохода.',
        benefit: 'Основные преимущества',
        benefitTitle0: 'Высокая прибыль',
        benefitText0: 'Пользователи могут получать высокую прибыль, стекая свои SOL активы, увеличивая их стоимость.',
        benefitTitle1: 'Диверсифицированные инвестиции',
        benefitText1: 'Sol.fi позволяет пользователям участвовать в стекинге и ликвидностном майнинге на одной платформе, снижая риски за счет диверсификации инвестиций.',
        benefitTitle2: 'Открытая экосистема',
        benefitText2: 'Как часть открытой экосистемы, Sol.fi будет постоянно расширяться и добавлять больше отличных DeFi протоколов, предоставляя пользователям более широкие финансовые услуги и возможности для получения дохода.',
        friend: 'Партнеры',
        friendDesc: 'Стратегия стекингового пула Sol.fi предоставляется компанией Seven Seas, одним из ведущих строителей стекинговых пулов в области DeFi. Сотрудничая с Seven Seas, Sol.fi может предоставлять пользователям надежные и безопасные стекинговые услуги, постоянно оптимизируя стратегии для достижения более высокой прибыли. Через Sol.fi мы надеемся способствовать развитию экосистемы Solana, предоставляя пользователям эффективные и безопасные децентрализованные финансовые услуги, позволяя большему количеству людей наслаждаться удобствами и инновациями, которые приносит блокчейн-технология.'
    },
    pay: {
        desc: 'Обеспечьте свое будущее на Solana – ваш надежный кошелек с молниеносными транзакциями!',
        days: 'дней',
        hours: 'часов',
        minutes: 'минут',
        seconds: 'секунд',
        android: 'Скачать для Android',
        ios: 'Скачать в App Store'
    },
    lp:{
        title: 'Стекьте SOL быстрее',
        title1: 'SolFi делает стекинг SOL проще, предоставляя инвесторам и участникам сообщества устойчивую ценность и значительную отдачу.',
        stackDesc: 'SolFi делает стекинг SOL проще, предоставляя инвесторам и участникам сообщества устойчивую ценность и значительную отдачу.',
        stackList1: 'Мой стекинг',
        stackList2: 'Заработано',
        stackList3: 'Получено с помощью компаундинга',
        lp: 'Стекинг',
        underway: 'В процессе стекинга',
        earn: 'Заработанный доход',
        yet: 'Доход к выводу',
        redeem: 'Выкуп',
        yetBut: 'Вывести',
        accredit: 'Авторизовать',
        record: 'История стекинга',
        tokenTitle: 'Доход с компаундингом',
        tokenDesc: 'Доходы от стекинга, которые не выводятся, будут приносить компаундинговый доход, и токены SOLFI будут выдаваться по курсу 1 SOLFI = 1 SOL. Токен SolFi (SOLFI) будет функциональным токеном для всей платформы, позволяя пользователям участвовать в интегрированных AI-сервисах и управлении сообществом, и будет очень ценным в будущем.',
        tokenList1: 'Доступен ли компаундинг',
        tokenList2: 'Полученный компаундинговый доход',
        tokenList3: 'Доход от компаундинга к выводу',
        yes: 'Да',
        no: 'Нет',
        back: 'Вернуться к стекингу',
        money: 'Сумма',
        timeStart: 'Время начала стекинга',
        timeEnd: 'Время окончания',
        cancel: 'Отменить автообновление',
        more: 'Больше...',
        max: 'Максимум',
        quotaRemain: 'Остаток',
        usable: 'Доступно для стекинга',
        redeemed: 'Выкуплено',
        redeeming: 'Выкуп в процессе...',
        staking: 'Стекинг в процессе...'
    },
    award:{
        communityDesc: 'Члены сообщества, внесшие вклад в развитие сообщества, получат специальные награды.',
        communityList1: 'Полученные награды',
        communityList2: 'Выведено',
        communityList3: 'Доступно к выводу',
        communityBut: 'Вывести',
        record: 'История наград',
        listType1: 'Реферальные награды',
        listType2: 'Командные награды',
        listType3: 'Награды за одинаковый уровень',
        listType4: 'Дивидендные награды'
    },
    top:{
        tokenDesc: 'Токен SolFi (SOLFI) будет функциональным токеном для всей платформы, позволяя пользователям участвовать в интегрированных AI-сервисах и управлении сообществом, и будет очень ценным в будущем.',
        price: 'Цена',
        allAmount: 'Общее предложение',
        market: 'Рыночная стоимость',
        my: 'Мои SOLFI',
        balance: 'Баланс кошелька',
        buy: 'Swap',
        approve: 'Подтвердить SOL',
        approveTop: 'Подтвердить SOLFI',
        earn: 'Заработано',
        getYet: 'Доступно к получению',
        get: 'Получить'
    },
    ido:{
        turn: 'Текущий раунд',
        all: 'Общее количество IDO',
        price: 'Цена',
        limit: 'Лимит подписки',
        ps: 'Метод разблокировки: после блокировки на [] месяцев, разблокировка будет происходить линейно в течение 12 месяцев',
        my: 'Мой лимит',
        nobuy: 'Не подписан',
        balance: 'Баланс',
        custom: 'Предполагаемый доход',
        buy: 'Купить сейчас',
        buyYet: 'Уже куплено',
        record: 'История подписок',
        buyTrun: 'Раунд покупки',
        buyUsdt: 'Купить за SOL',
        getTop: 'Получить SOLFI',
        unlock: 'Следующее время разблокировки',
        unlockYet: 'Разблокированные SOLFI',
        claimable: 'Доступные SOLFI',
        get: 'Получить',
        nodataText: 'Нет записей о подписке'
    },
    user:{
        desc: 'Делитесь SolFi, помогая людям получать значительный доход в области блокчейн.',
        joinTime: 'Время регистрации',
        memberTeam: 'Члены команды',
        memberDirect: 'Прямые рефералы',
        teamLp: 'Командный стекинг',
        myLink: 'Моя реферальная ссылка',
        myTeam: 'Моя команда',
        personage: 'Личный стекинг',
        cur: 'Текущий',
        lookDown: 'Просмотреть ниже уровни',
        copy: 'Копировать',
        info: 'Личная информация'
    }
}

export default ru;
