import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/home/index.vue')
    },
    {
        path: '/pay',
        name: 'pay',
        component: () => import('../views/home/pay.vue')
    },
    {
        path: '/lp',
        name: 'lp',
        component: () => import('../views/lp/index.vue')
    },
    {
        path: '/lprecord',
        name: 'lprecord',
        component: () => import('../views/lp/record.vue')
    },
    {
        path: '/award',
        name: 'award',
        component: () => import('../views/award/index.vue')
    },
    {
        path: '/solfi',
        name: 'solfi',
        component: () => import('../views/top/index.vue')
    },
    /* {
        path: '/ido',
        name: 'ido',
        component: () => import('../views/top/ido.vue')
    }, */
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/index.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior() {
        return {top: 0 }
    },
    routes
})

// 解决跳转到当前路由报错问题
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch((err) => err)
// }


export default router
