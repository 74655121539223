import { createI18n } from 'vue-i18n/index';

import cn from './config/cn';
import en from './config/en';
import tc from './config/cn-tw';
import ru from './config/ru';
import th from './config/th';
import rok from './config/rok';
import jp from './config/jp';
import vi from './config/vi';
import id from './config/id';
import lao from './config/lao';


const i18n = createI18n({
    locale: localStorage.getItem('locale') || 'en',
    silentTranslationWarn: true,
    messages: {
        cn,
        en,
        tc,
        ru,
        th,
        rok,
        jp,
        vi,
        id,
        lao
    }
})

export default i18n;
