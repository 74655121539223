const zh = {
    alert:{
        reLogin: 'Alamat telah berubah, silakan login kembali',
        copied: 'Berhasil menyalin',
        connectWallet: 'Silakan hubungkan dompet',
        notBound: 'Alamat referensi belum terikat, silakan ikat',
        inputParent: 'Silakan masukkan alamat referensi',
        addressError: 'Silakan masukkan alamat dompet yang benar',
        parentError: 'Alamat referensi salah, silakan masukkan kembali',
        boundSuccess: 'Berhasil terikat',
        boundFailed: 'Gagal mengikat, silakan coba lagi',
        success: 'Berhasil',
        failed: 'Gagal',
        soon: 'Segera dibuka...',
        insufficient: 'Saldo tidak mencukupi',
        quotaInsufficient: 'Kuota tidak mencukupi',
        amountError: 'Jumlah salah',
        minAmountError: 'Minimum',
        redeemed: 'Pesanan tidak ada atau sudah ditebus',
        enter: 'Konfirmasi',
        congratulation: 'Selamat Anda telah berhasil menggabungkan',
        walletInsufficient: 'Saldo dompet tidak mencukupi',
        copySuccess: 'Berhasil menyalin'
    },
    header: {
        link: 'Hubungkan dompet',
        home: 'Beranda',
        lp: 'Staking',
        award: 'Hadiah',
        solfi: 'SOLFI',
        user: 'Akun Saya',
        ido: 'IDO',
        inc: '© 2024 SolFi Inc. Hak cipta dilindungi undang-undang.',
        bind: 'Ikat',
        bindPla: 'Silakan masukkan alamat referensi',
        bindTitle: 'Silakan ikat alamat referensi'
    },
    home:{
        launchTitle: 'Sol.fi adalah',
        launchTitle1: 'Perpustakaan Strategi DeFi Otomatis',
        launchTitle2: 'yang bertujuan menyediakan layanan keuangan terdesentralisasi yang sederhana dan efisien untuk pengguna di blockchain Solana.',
        launchDesc: 'Kami berkomitmen untuk membangun ekosistem yang terbuka dan beragam, memungkinkan pengguna untuk dengan mudah menggunakan aset SOL mereka dalam berbagai aktivitas staking dan penambangan likuiditas, sehingga mencapai pertumbuhan keuntungan yang stabil.',
        but1: 'Staking',
        but2: 'Whitepaper',
        diversifiedTitle0: 'Sederhana',
        diversifiedTitle10: 'Beragam',
        diversifiedDesc0: 'Sol.fi menyediakan solusi terpadu untuk staking SOL dan perdagangan terdesentralisasi, memungkinkan pengguna untuk berpartisipasi dalam aktivitas DeFi yang beragam tanpa operasi yang rumit.',
        diversifiedTitle1: 'Terdesentralisasi',
        diversifiedTitle11: 'Protokol Keuangan',
        diversifiedDesc1: 'Sebagai bagian dari blockchain Solana, Sol.fi menciptakan protokol keuangan yang sepenuhnya terdesentralisasi, memastikan keamanan aset pengguna serta menikmati pengalaman transaksi dan staking dengan biaya rendah.',
        diversifiedTitle2: 'Multi-Protokol',
        diversifiedTitle12: 'Dukungan',
        diversifiedDesc2: 'Kolam penambangan likuiditas Sol.fi saat ini staking di protokol terkenal seperti Jupiter, Raydium, dan Orca, dan di masa depan akan terus menambahkan lebih banyak protokol DeFi berkualitas untuk memberikan lebih banyak pilihan investasi dan peluang keuntungan bagi pengguna.',
        benefit: 'Keuntungan Utama',
        benefitTitle0: 'Keuntungan Tinggi',
        benefitText0: 'Pengguna dapat mendapatkan keuntungan tinggi melalui staking aset SOL, mencapai peningkatan nilai aset.',
        benefitTitle1: 'Investasi Beragam',
        benefitText1: 'Sol.fi memungkinkan pengguna untuk berpartisipasi dalam staking dan penambangan likuiditas secara all-in-one, mencapai diversifikasi investasi dan mengurangi risiko.',
        benefitTitle2: 'Ekosistem Terbuka',
        benefitText2: 'Sebagai bagian dari ekosistem terbuka, Sol.fi akan terus berkembang dan menambahkan lebih banyak protokol DeFi berkualitas untuk menyediakan layanan keuangan yang lebih luas dan peluang keuntungan bagi pengguna.',
        friend: 'Mitra',
        friendDesc: 'Strategi kolam staking Sol.fi disediakan oleh Seven Seas, salah satu pembangun kolam staking teratas di bidang DeFi. Melalui kemitraan dengan Seven Seas, Sol.fi dapat menyediakan layanan staking yang aman dan andal kepada pengguna, dan terus mengoptimalkan strategi staking untuk mencapai keuntungan yang lebih tinggi. Melalui Sol.fi, kami berharap dapat berkontribusi pada pengembangan ekosistem Solana, menyediakan layanan keuangan terdesentralisasi yang efisien dan aman, dan memungkinkan lebih banyak orang menikmati kenyamanan dan inovasi yang dibawa oleh teknologi blockchain.'
    },
    pay: {
        desc: 'Amankan masa depan Anda di Solana – Dompet yang dapat Anda percaya, transaksi secepat kilat!',
        days: 'hari',
        hours: 'jam',
        minutes: 'menit',
        seconds: 'detik',
        android: 'Unduh Android',
        ios: 'Unduh di App Store'
    },
    lp:{
        title: 'Stack SOL lebih cepat',
        title1: 'SolFi membuat staking SOL menjadi lebih sederhana, memberikan nilai berkelanjutan dan pengembalian yang signifikan bagi investor dan anggota komunitas.',
        stackDesc: 'SolFi membuat staking SOL menjadi lebih sederhana, memberikan nilai berkelanjutan dan pengembalian yang signifikan bagi investor dan anggota komunitas.',
        stackList1: 'Staking Saya',
        stackList2: 'Telah Diperoleh',
        stackList3: 'Telah Diperoleh dengan Bunga Majemuk',
        lp: 'Staking',
        underway: 'Staking sedang berjalan',
        earn: 'Pendapatan Diperoleh',
        yet: 'Pendapatan Dapat Ditarik',
        redeem: 'Tebus',
        yetBut: 'Tarik',
        accredit: 'Otorisasi',
        record: 'Catatan Staking',
        tokenTitle: 'Pendapatan Bunga Majemuk',
        tokenDesc: 'Pendapatan yang dihasilkan dari staking yang tidak ditarik akan menghasilkan pendapatan bunga majemuk, dan token SOLFI akan didistribusikan dengan rasio 1SOLFI = 1SOL. Token SolFi (SOLFI) akan berfungsi sebagai token fungsional untuk seluruh platform, yang dapat digunakan untuk berpartisipasi dalam layanan AI terintegrasi dan tata kelola komunitas, dan akan sangat berharga di masa depan.',
        tokenList1: 'Apakah bisa mendapatkan bunga majemuk',
        tokenList2: 'Telah mendapatkan pendapatan bunga majemuk',
        tokenList3: 'Pendapatan bunga majemuk yang dapat ditarik',
        yes: 'Ya',
        no: 'Tidak',
        back: 'Kembali ke Staking',
        money: 'Jumlah',
        timeStart: 'Waktu Staking',
        timeEnd: 'Waktu Berakhir',
        cancel: 'Batalkan Pembaruan Otomatis',
        more: 'Lebih banyak...',
        max: 'Maksimum',
        quotaRemain: 'Saldo',
        usable: 'Dapat Di-stake',
        redeemed: 'Telah Ditebus',
        redeeming: 'Menebus...',
        staking: 'Staking sedang berjalan...'
    },
    award:{
        communityDesc: 'Anggota komunitas yang berkontribusi terhadap pengembangan komunitas akan mendapatkan hadiah khusus dari komunitas.',
        communityList1: 'Hadiah yang Diperoleh',
        communityList2: 'Telah Ditarik',
        communityList3: 'Dapat Ditarik',
        communityBut: 'Tarik',
        record: 'Catatan Hadiah',
        listType1: 'Hadiah Referensi Langsung',
        listType2: 'Hadiah Tim',
        listType3: 'Hadiah Setara',
        listType4: 'Hadiah Dividen'
    },
    top:{
        tokenDesc: 'Token SolFi (SOLFI) akan berfungsi sebagai token fungsional untuk seluruh platform, yang dapat digunakan untuk berpartisipasi dalam layanan AI terintegrasi dan tata kelola komunitas, dan akan sangat berharga di masa depan.',
        price: 'Harga',
        allAmount: 'Total Pasokan',
        market: 'Kapitalisasi Pasar',
        my: 'SOLFI Saya',
        balance: 'Saldo Dompet',
        buy: 'Swap',
        approve: 'Setujui SOL',
        approveTop: 'Setujui SOLFI',
        earn: 'Pendapatan yang Diperoleh',
        getYet: 'Dapat Diambil',
        get: 'Ambil'
    },
    ido:{
        turn: 'Putaran saat ini',
        all: 'Total IDO',
        price: 'Harga',
        limit: 'Kuota Berlangganan',
        ps: 'Metode Penguncian: Terkunci selama [] bulan, dibuka secara linear dalam 12 bulan',
        my: 'Kuota Saya',
        nobuy: 'Belum Berlangganan',
        balance: 'Saldo',
        custom: 'Perkiraan Diperoleh',
        buy: 'Langsung Berlangganan',
        buyYet: 'Sudah Berlangganan',
        record: 'Catatan Berlangganan',
        buyTrun: 'Putaran Berlangganan',
        buyUsdt: 'Berlangganan SOL',
        getTop: 'Dapatkan SOLFI',
        unlock: 'Waktu Pembukaan Kunci Berikutnya',
        unlockYet: 'SOLFI yang Telah Dibuka Kuncinya',
        claimable: 'SOLFI yang Dapat Diambil',
        get: 'Ambil',
        nodataText: 'Tidak ada catatan berlangganan'
    },
    user:{
        desc: 'Bagikan SolFi, bantu lebih banyak orang yang membutuhkan, dan hasilkan keuntungan yang signifikan di bidang blockchain.',
        joinTime: 'Waktu Pendaftaran',
        memberTeam: 'Anggota Tim',
        memberDirect: 'Anggota Referensi Langsung',
        teamLp: 'Staking Tim',
        myLink: 'Tautan Undangan Saya',
        myTeam: 'Tim Saya',
        personage: 'Staking Pribadi',
        cur: 'Saat ini',
        lookDown: 'Lihat Bawahan',
        copy: 'Salin',
        info: 'Informasi Pribadi'
    }
}

export default zh;