<template>
    <div class="no-data">
        <img :src="require('../static/images/' + img + '.png')" />
    </div>
</template>
<script setup>
    import { defineProps, ref, onMounted } from 'vue';

    const props = defineProps(['img'])
    const img = ref('no-data')
    onMounted(() => {
        if(props.img){
            img.value = props.img
        }
    })
</script>